import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class ActionService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getShipById(client: string, id: string, fieldnames: string) {
    const params = {
      client,
      fieldnames,
    };
    return ClientAPI.Get(`ship/${id}`, params);
  }

  async postShip(ship: any) {
    const params = { includePayload: "true" };
    return ClientAPI.Post(`ship`, ship, params);
  }
}
